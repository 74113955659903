/* https://techstacker.com/put-elements-bottom-container-css/ */
.observerButton {
    position: relative;
    display: flex;
    width: 20vw;
    height: 20vw;
    margin-right: 30px;
    margin-bottom: 3vh;
    background-color: lightgray;
    border-radius: 10px;
    border: 1px solid black;
    /* border-color: black; */
    overflow: hidden;
}

.observerButtonLabel {
    text-align: center;
    font-size: 2vw;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30%;
    padding-bottom: 10px;
    background-color: black;
    color: white;
}

.observerImage {
    position: relative;
    top: 0;
    object-position: contain;
    align-items: end;
    justify-content: right;
}
