.container-event-list {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    /* margin-left: 30px;
    margin-right: 30px; */
}
.container-event-list::-webkit-scrollbar {
    height: 8px;
}
.container-event-list::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 10px;
}
.container-event-list::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 10px;
}
