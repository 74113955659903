.Calendar {
    font-family: sans-serif;
    text-align: center;
    width: 90vw;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 5vh;
    padding-bottom: 5vh;
}

.dialogueBox {
    text-align: center;
    /* padding-left: 40px; */
    /* padding: 1rem; */
}

.divStyle {
    display: block grid;
    flex-direction: row;
    /* bottom: 0px; */
    justify-content: center;
    /* align-items: center;
    background-color: aqua; */
    /* padding: 1rem; */
}

.dialogueButtonStyle {
    /* width: 5rem; in order for the width attribute to work, I have to set the width of the span*/
    height: 1.5rem;
    font-size: 1rem;
    background-color: rgb(214, 213, 213);
    color: black;
    margin-bottom: 5px;
    border-radius: 10px;
    border: 1px solid black;
    justify-content: right;
 }
