@import url('https://fonts.cdnfonts.com/css/olde-english');

.app {
}

.main {
    margin-bottom: 10px; /* if we set margins we cannot set 'bottom' in footer. The size is in px instead of vh because
    not all subelements are responsive with vh */
}

h1.mainTitle {
    padding-top: 2vh;
    padding-bottom: 2vh;
    text-align: center;
    font-size: 3rem;
}

.sendMessageDiv {
    align-items: center;
    justify-content: center;
}

h2 {
    text-align: center;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: 2rem;
}

.primaryButton {
    /* margin-left: 5vw; */
    border: 2px solid;
    border-radius: 7px;
    border-color: black;
    background-color: #C7A446;
    color: black;
    width: 80px;
    height: 30px;
    font-size: 1rem;
  }
  
.primaryButton:hover {
    background-color: black;
    color: #C7A446;
    cursor: pointer;
}

.events-main {
    margin-right: 3vw;
    margin-left: 3vw;
}

.contactContainer {
    padding-left: 5vw;
    padding-right: 5vw;
}

.contactMain {
    display: flex;
    margin-top: 50px;
}
.contactDescription {
    /* display: flex; */
    justify-content: left;
    align-items: start;
    margin-right: 2vw;
    width: 20vw;
    font-size: 1.5rem;
}
.contactMapDiv {
    display: flex;
}
.contactMap {
    /* margin-right: 2vw;
    margin-left: 3vw; */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 68vw;
    height: 70vh;
    cursor: pointer;
}
@media screen and (max-width: 500px) {
    .contactMain {
        display: block;
    }
    
    .contactDescription {
        width: 90vw;
    }
    .contactMapDiv {
        justify-content: center;
        height: 40vh;
    }
}
