@import '../../../App.css';

ul.homeInfo {
    list-style-type: none;
    padding: 0;
}

.homeInfo {
    display: block;
    align-items: center;
    justify-items: center;
    text-align: center;
}
