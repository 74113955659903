.carousel-container {
    background: #f1f1f1;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    /* position:fixed; This means the item stays fixed even if the content is scrolled https://developer.mozilla.org/en-US/docs/Web/CSS/flex-direction */
    top:80px;
}

.carousel-item {
    height: 70vh !important;
    /* max-height: 500px; use vh instead of % https://stackoverflow.com/questions/31728022/why-is-percentage-height-not-working-on-my-div */
    /* height: 20%; */
    /* max-height: 20%; */
    min-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    object-position: 0% 37%;
    object-fit: cover;
    /* transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1); */
    transition: 1s ease-in-out;
    aspect-ratio: auto;
    /* transition: opacity 0s 1s; */
}
