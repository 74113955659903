.container-event-list-item {
    width: 30vw;
    /* The following attributes to center the image
    in our main container */
    text-align: center;
    vertical-align: middle;
}

.event-item {
    width: 30vh;
    height: 60vh;
    object-fit: cover;
    aspect-ratio: auto;
}

h2.event-list-item {
    text-align: center;
}

h3.event-list-item {
    text-align: center;
}
