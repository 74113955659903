@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.cdnfonts.com/css/olde-english');
@import "../../App.css";

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #fcfcfc;
}

.navbar {
  height: 80px;
  background-color: #C7A446;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.container {
  /* max-width: 100vh; */
  /* margin: 0 auto; */
  padding: 0 15px;
  display: flex;
  /* justify-content: space-between; If I want to specify the space, I cannot use this line. This is for vertical align */
  align-items: center; /* This is for horizontal space, top and bottom */
  height: 100%;
  /* justify-content: space-around; */
}

.navbar .title {
  font-family: 'Olde English', sans-serif;
  font-size: 2vw;
  font-weight: 400;
  color: #000000;
  word-wrap: break-word;
  justify-content: left;
  text-align: center;
  overflow: hidden;
  padding-left: 20px;
}

.logo {
  height: 80px;
  width: 80px;
  object-fit: contain;
}

.menu-icon {
  display: none;
}

.nav-elements {
  margin-left: auto; /* This is what I need to align donation button right later on, this will be removed from here */
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: clamp(12px, 1vw, 20px); /* clamp(minimum-size, prefered-size, maximum-size) */
  font-weight: 400;
  color: #000000;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #000000;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #000000;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
  .navbar .title {
    font-size: 25px;
    padding: 0 10px;
  }

  .primaryButton {
  }
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 80px;
    background-color: #fef7e5;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
  
  .nav-elements ul a {
    font-size: 20px;
  }

  .primaryButton {
    /* display: flex;
    flex-direction: column;
    position: absolute; */
    margin-right: 30px;
    width: 40vw;
  }
}
