.page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
@media (max-width: 600px) {
    .page {
        width: 100vh;
        padding-right: -50vh;
    }
}

nav.pdf {
    display: flex;
    /* margin: 1rem auto; */
    align-items: center;
    justify-content: space-between;
}

button.pdf {
    background-color: #f5f5f5;
    border: none;
    color: #000;
    padding: 1rem;
    list-style: none;
    font-size: 1.3rem;
    margin: 0.7rem;
    border-radius: 20%;
    cursor: pointer;
}

button.previous {
    background-color: #f1f1f1;
    color: black;
}

button.next {
    background-color: #000000;
    color: white;
}
