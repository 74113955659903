.introContainer {
    margin-left: 5vw;
    margin-right: 5vw;
}

.introDescription {
    font-size: 1.5rem;
}

p.introAuthor {
    text-align: end;
    font-style: italic;
}