@import "../../../App.css";

.sendMessage {
    display: block;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
}

input.field {
    /* max-width: 90vw; */
    width: 20vw;
    height: 5vh;
}

textarea.msgBox {
    width: 50vw;
    height: 20vh;
}

.subButton {
    /* align-items: center;
    justify-content: center; */
    /* background-color: black; */
    /* margin: 0 0 0 -40px; */
    margin: 0 auto;
    /* position: absolute;
    left: 50%; */
    /* bottom: 10%;
    top: 5%; */
    /* padding-bottom: 300px; */
    /* padding-bottom: 5vh;
    padding-top: 5vh; */
}

.messageElem {
    padding: 2vw;
    width: 100%;
}
