@import url('https://fonts.cdnfonts.com/css/olde-english');

.Box {
    padding: 0px 0px;
    background: #000;
    position: absolute;
    /* bottom: 0;
    left: 0;
    right: 0; */
    width: 100%;
    height: 410px;
}
@media (max-width: 600px) {
    .Box {
        padding: 70px 0px;
    }
}

h1.footer {
    font-family: 'Olde English', sans-serif;
    font-size: 40px;
    font-weight: 400;
    word-wrap: break-word;
    text-align: center;
    overflow: hidden;

    color: #C7A446;
	text-align: center;
	margin-top: 30px;
}

p.footer {
    color:white;
    text-align: center;
    font-size: 20px;
}

.Container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
    /* background: red; */
}

.Column {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 60px;
    /* padding-bottom: 80px; */
}

.Row {
    display: grid;
    grid-template-columns: repeat(auto-fill,
    minmax(185px, 1fr));
    grid-gap: 20px;
    
    @media (max-width: 1000px) {
        grid-template-columns: repeat(auto-fill,
        minmax(200px, 1fr));
    }
}

.foot-elements {
    margin: 0;
}

.foot-elements ul {
    display: flex;
    justify-content: space-around;
    list-style-type: none;
}

.foot-elements ul a {
    color: #fff;
    font-size: 18px;
    text-decoration: none;
}

.foot-elements ul a:hover {
    color: #C7A446;
    transition: 200ms ease-in;
}

.Heading {
    font-size: 24px;
    color: #fff;
    margin-bottom: 40px;
    font-weight: bold;
}
